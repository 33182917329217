import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { OnBoardingService } from 'src/app/shared/services/on-boarding.service';
import { OAuthSessionResponse } from '../shared/models/OAuthToken';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../Connector/services/common.service';
import { SpinnerService } from '../shared/spinner/spinner.service';

@Component({
  selector: 'app-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.css']
})
export class UtilitiesComponent implements OnInit {
  oAuthToken: OAuthSessionResponse;
  private commonService: CommonService;
  private spinnerService: SpinnerService;
  errorMessage: any;
  constructor(private authService: AuthenticationService, private onBoardingService: OnBoardingService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    let realmId = this.route.snapshot.queryParams["RealmId"];
    localStorage.setItem("realmId", realmId);
    localStorage.removeItem("qbo_session");
    localStorage.removeItem("IsLoadedBefore");
    this.signIn();
  }
  signIn(): void {
    try {
      this.onBoardingService.removeOnboarding();
      this.authService.startAuthentication();
    } catch (error) { console.log(error); }
  }

}


