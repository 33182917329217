import { Component, OnInit, Injectable, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DeactivationGuarded } from 'src/app/shared/gaurds/candeactivate.guard';
import { BatchAddressValidationService } from '../services/batchaddressvalidation.service'
import { FetchCustomerResponse } from '../models/batchAddressModel'
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CommonService } from '../services/common.service';
import { userCredentials } from '../models/UserCredentials';
import { OAuthSessionResponse } from '../../shared/models/OAuthToken';
import { DataTableDirective } from "angular-datatables";
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-batchaddress',
  templateUrl: './batchaddress.component.html',
  styleUrls: ['./batchaddress.component.css']
})
export class BatchAddressComponent implements OnInit, DeactivationGuarded {

  searchForm: FormGroup;
  oAuthToken: OAuthSessionResponse;
  userCreds: userCredentials;
  errorMessage: any;
  showAlert: boolean = false
  alertMessage: string
  alertCssClass: string;
  scrollTop: number = 0
  showValidateAvaTaxAccountButton: boolean;
  accountValidationMessage: string;

  qboCustomerList: Array<FetchCustomerResponse> = [];
  allQboCustomerList: Array<FetchCustomerResponse> = [];
  qboCustomerRequest: Array<string> = [];
  isCustomerList: boolean = false;
  isCustomerListLoaded: boolean = false;
  filterCustomerText: string = '';

  selectedIds: Array<string> = [];
  isSelectAllChecked: boolean = false;

  statusClass: string;
  iterationCnt: number = 0;
  filterIterationCnt: number = 0;

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  isPageRefreshed: boolean = false;
  mySubscription: any;
  sourceParam: string;


  constructor(private batchAddressValidationService: BatchAddressValidationService,
    private commonService: CommonService,
    private spinnerService: SpinnerService,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  canDeactivate(): boolean | Promise<boolean> {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      if (this.searchForm && this.searchForm.dirty) {
        return window.confirm('There are some unsaved changes. Do you really want to navigate away?')
      }
    }
    return true;
  }

  async ngOnInit() {

    this.sourceParam = this.route.snapshot.paramMap.get("source");
    this.sourceParam = this.sourceParam == null ? "" : this.sourceParam;
  
    if (this.sourceParam != "") {
      $("#connectorHeader").hide();
      $("#dvLoginDetail").hide();
      $("#avaHeader").show();
      $("#avaHeader-content").show();
    }
  
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      this.spinnerService.show();
      
      // Refresh JWT Token - Extend 25mins more with new JWT Token from API
      await this.commonService.extendJwtDuration();
  
      this.initializeDatatables();

      if (!this.router.navigated) {
        this.searchQboCustomers();
      }

      this.spinnerService.hide();
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/learnmore']);
    }
  }
  

  initializeDatatables() {
    this.dtOptions[0] = {
      retrieve: true,
      paging: true,
      lengthChange: true,
      searching: false,
      pageLength: 10,
      columnDefs: [{ targets: 3, orderable: false }],
      pagingType: 'simple_numbers',
      order: [[1, 'desc']],
      serverSide: false,
      processing: false
    }
  }

  objectToHttpParams(obj: any) {
    return Object.entries(obj || {}).reduce((params, [key, value]) => {
      return params.set(
        key,
        value === 'object' ? JSON.stringify(value) : String(value)
      );
    }, new HttpParams());
  }

  searchQboCustomers() {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
        this.fetchQboCustomers(this.oAuthToken.userId, this.oAuthToken.session_Id);
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/learnmore']);
    }
  }

  fetchQboCustomers(id: string, authToken: string): void {
    try {
      var current = this;
      current.iterationCnt = 0;
      current.statusClass = "";
      current.isCustomerListLoaded = false;
      current.spinnerService.show();

      this.batchAddressValidationService.fetchQboCustomers(id, authToken).subscribe(
        (response: any) => {
          console.log("qboCustomerList count: ", response);

          if (response != null && response != undefined) {
           
            current.allQboCustomerList = response;
            current.qboCustomerList = current.allQboCustomerList;

            console.log("qboCustomerList count: ", current.qboCustomerList.length);
            current.rerender();
            if(current.qboCustomerList.length > 0)
              this.isCustomerList = true;

          current.spinnerService.hide();
      }
        },
        (error: any) => {
          this.errorMessage = <any>error;
          this.spinnerService.hide();
          if(this.errorMessage != "unauthorized")
            alert(this.errorMessage);
        },
        () => { }
      );
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }
  }

  filterCustomer(status){
    var current = this;
    current.spinnerService.show();
    this.iterationCnt = this.iterationCnt + 1;
    if(this.iterationCnt == 1){
      this.qboCustomerList = new Array<FetchCustomerResponse>();
      setTimeout(function(){current.filterCustomer(status); }, 500);
    }
    else {
      this.iterationCnt = 0;
      if(this.allQboCustomerList.length > 0) {
      if(status == "filter"){
        if(current.filterCustomerText === ''){
          this.qboCustomerList = this.allQboCustomerList.filter(o => o.id == "");
          this.rerender();
        }
        else {
          this.qboCustomerList = this.allQboCustomerList.filter(o => o.id.includes(current.filterCustomerText.trim()) || (o.customerName == null ? "": o.customerName).includes(current.filterCustomerText.trim()));
          this.rerender();
        }
      }
      else
      {
        this.filterCustomerText = "";
        if(status == "Validation Failed")
          this.qboCustomerList = this.allQboCustomerList.filter(o => o.status == status);
          if(status == "Not Validated")
          this.qboCustomerList = this.allQboCustomerList.filter(o => o.status == status);
        else 
          this.qboCustomerList = this.allQboCustomerList.filter(o => o.status == status);
        this.rerender();
      }
    }
      current.spinnerService.hide();
    }
    this.statusClass = status;
  }

  rerender(): void {
    if (this.dtElements) {
      this.dtElements.forEach((dtElement: DataTableDirective) => {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.clear();
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      });
    }
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  selectAll(event) {
    if (event.target.checked) {
      this.isSelectAllChecked = true;
      var selectedCount = 0;
      for (var row of this.qboCustomerList) {
        if(row.status != "Validated"){
          row.isSelected = true;
          selectedCount++;
        }
      }
      alert("All (" + selectedCount + ") Customer's Shipping addresses will be selected.")
    }
    else {
        for (var row of this.qboCustomerList) {
          row.isSelected = false;
        }
    }
  }

  selectCustomerValue(target) {
    let isSelected = target.checked;
    for (var row of this.qboCustomerList) {
      if (row.id == target.value) {
        row.isSelected = isSelected;
        break;
      }
    }
    if (!target.checked) {
      this.isSelectAllChecked = false;
    }
  }

  removeSpace(status) {
  return status.replace(" ","");
  }
 

  clearForm() {
    this.initializeDatatables();
    this.qboCustomerList = [];
    this.isCustomerList = false;
    this.dtTrigger.next();
    this.statusClass = "";
    this.isCustomerListLoaded = false;
  }
 
  SendForAddressValidation() {

    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {

      let isSelected = false;
      for (var row of this.qboCustomerList) {
        if (row.isSelected) {
          isSelected = true;
          break;
        }
      }

      if (isSelected) {
        for (var row of this.qboCustomerList) {
          if (row.isSelected) {
            if (row.id !== "") {
              if (row.status === "Validated" && row.status !== null){
              alert(" You had selected Validated Status \n Please select Not Validated/Validation Failed to Sync Batch Address");
              return;
            }
          }
        }
      }
    } 

      if (isSelected) {
        for (var row of this.qboCustomerList) {
          if (row.isSelected) {
            if (row.id != "") {
              this.qboCustomerRequest.push(row.id);
            }
          }
        }

        console.log("qboCustomerRequest", this.qboCustomerRequest);

        if (this.qboCustomerRequest.length > 0) {
          var current = this;
          current.spinnerService.show();
          this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
          console.log("oAuthToken", this.oAuthToken);
          
          if (confirm("1.Address validation will be done in the background.\n 2.Background process will be initiated in less than three minutes and depending on the number of addresses this may take few minutes to complete the Address Validation.\n 3.To see the current status click on 'Refresh data' link.") === false)
            {
              current.spinnerService.hide();
              return false;
            }

          if (this.oAuthToken) {
            this.batchAddressValidationService.sendForAddressValidation(this.oAuthToken.userId, current.qboCustomerRequest, this.oAuthToken.session_Id)
              .subscribe(
                (model: any) => {
                  if (model == true) {
                    
                    current.qboCustomerRequest = [];
                    for (var row of current.qboCustomerList) {
                      row.isSelected = false;
                    }
                  }
                  current.spinnerService.hide();
                },
                (error: any) => {
                  this.errorMessage = <any>error;
                  this.spinnerService.hide();
                  if(this.errorMessage != "unauthorized")
                    alert(this.errorMessage);
                },
                () => { }
              );
          }
        }
      }
      else {
        alert("Please select QuickBooks customers for address validation.");
      }
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/learnmore']);
    }

    return false;
  }
}
